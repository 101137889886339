import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MutationService, QueryClientService } from '@ngneat/query';
import { environment } from '../../../../environments/environment';
import { AuthRepository } from './auth.repository';
import { FormGroup } from '@angular/forms';
import { LoginForm } from '../forms/login.form';
import { LoginOutput, RefreshTokenOutput } from './auth.types';
import { tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly _httpClient = inject(HttpClient);
  private readonly _authRepository = inject(AuthRepository);
  private readonly _mutationService = inject(MutationService);
  private readonly _queryClientService = inject(QueryClientService);

  refreshToken(refreshToken: string) {
    return this._httpClient.post<RefreshTokenOutput>(
      `${environment.apiUrl}/auth/refresh`,
      {
        refresh_token: refreshToken,
      },
    );
  }

  login() {
    return this._mutationService.use((data: FormGroup<LoginForm>) => {
      return this._httpClient
        .post<LoginOutput>(
          `${environment.apiUrl}/auth/pincode-login`,
          data.getRawValue(),
        )
        .pipe(
          tap((response) => {
            this._authRepository.setToken(response.accessToken);
            this._authRepository.setRefreshToken(response.refreshToken);
            this._authRepository.setAuthDataToStorage({
              token: response.accessToken,
              refreshToken: response.refreshToken,
            });
          }),
        );
    });
  }

  logout() {
    this._queryClientService.clear();
    this._authRepository.clearAuthData();
  }
}
