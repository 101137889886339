import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { QueryClient as QueryClient$1, notifyManager, parseQueryArgs, QueryObserver, MutationObserver, InfiniteQueryObserver, parseFilterArgs, parseMutationFilterArgs } from '@tanstack/query-core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, shareReplay, take, tap, Subscription, BehaviorSubject, pipe, map, filter, distinctUntilChanged, startWith } from 'rxjs';
const QUERY_CLIENT_OPTIONS = new InjectionToken('QUERY_CLIENT_OPTIONS', {
  providedIn: 'root',
  factory() {
    return {};
  }
});
function provideQueryClientOptions(options) {
  return {
    provide: QUERY_CLIENT_OPTIONS,
    useValue: options
  };
}
const QueryClient = new InjectionToken('QueryClient', {
  providedIn: 'root',
  factory() {
    return new QueryClient$1(inject(QUERY_CLIENT_OPTIONS));
  }
});
const provideQueryClient = queryClient => {
  return {
    provide: QueryClient,
    useValue: queryClient
  };
};
class QueryClientMount {
  constructor() {
    this.instance = inject(QueryClient);
    this.instance.mount();
  }
  ngOnDestroy() {
    this.instance.unmount();
  }
  static {
    this.ɵfac = function QueryClientMount_Factory(t) {
      return new (t || QueryClientMount)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: QueryClientMount,
      factory: QueryClientMount.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QueryClientMount, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
const QueryClientService = new InjectionToken('QueryClientService', {
  providedIn: 'root',
  factory() {
    if (isPlatformBrowser(inject(PLATFORM_ID))) {
      inject(QueryClientMount);
    }
    return inject(QueryClient);
  }
});
function baseQuery(client, Observer, options) {
  const defaultedOptions = client.defaultQueryOptions(options);
  defaultedOptions._optimisticResults = 'optimistic';
  defaultedOptions.onError &&= notifyManager.batchCalls(defaultedOptions.onError);
  defaultedOptions.onSuccess &&= notifyManager.batchCalls(defaultedOptions.onSuccess);
  defaultedOptions.onSettled &&= notifyManager.batchCalls(defaultedOptions.onSettled);
  const queryObserver = new Observer(client, defaultedOptions);
  queryObserver.result$ = new Observable(observer => {
    const mergedOptions = client.defaultQueryOptions({
      ...options,
      // The query key can be changed, so we need to rebuild it each time
      ...queryObserver.options
    });
    observer.next(queryObserver.getOptimisticResult(mergedOptions));
    const queryObserverDispose = queryObserver.subscribe(result => {
      observer.next(!defaultedOptions.notifyOnChangeProps ? queryObserver.trackResult(result) : result);
    });
    return () => {
      queryObserverDispose();
    };
  }).pipe(shareReplay({
    bufferSize: 1,
    refCount: true
  }));
  return queryObserver;
}
function fromQueryFn(originalQueryFn, client, queryKey) {
  function queryFn$(queryFnArgs) {
    return new Promise((res, rej) => {
      const subscription = originalQueryFn(queryFnArgs).pipe(take(1), tap({
        unsubscribe: () => {
          client.cancelQueries(queryKey);
        }
      })).subscribe({
        next: res,
        error: rej
      });
      queryFnArgs.signal?.addEventListener('abort', () => {
        subscription.unsubscribe();
      });
    });
  }
  return queryFn$;
}
function buildQuery(client, Observer, options) {
  const originalQueryFn = options.queryFn;
  options.queryFn &&= fromQueryFn(originalQueryFn, client, options.queryKey);
  return baseQuery(client, Observer, options);
}
function createSyncObserverResult(data, options = {}) {
  return {
    data,
    isLoading: false,
    isError: false,
    isSuccess: true,
    status: 'success',
    ...options
  };
}
function someRequestsStatusOf(requests, status) {
  const toArray = Array.isArray(requests) ? requests : Object.values(requests);
  return toArray.some(req => req.status === status);
}
function allRequestsStatusOf(requests, status) {
  const toArray = Array.isArray(requests) ? requests : Object.values(requests);
  return !toArray.some(req => req.status !== status);
}
class QueryService {
  constructor() {
    this.instance = inject(QueryClientService);
  }
  use(arg1, arg2, arg3) {
    const parsedOptions = parseQueryArgs(arg1, arg2, arg3);
    return buildQuery(this.instance, QueryObserver, parsedOptions);
  }
  static {
    this.ɵfac = function QueryService_Factory(t) {
      return new (t || QueryService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: QueryService,
      factory: QueryService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QueryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const UseQuery = new InjectionToken('UseQuery', {
  providedIn: 'root',
  factory() {
    const query = new QueryService();
    return query.use.bind(query);
  }
});
class MutationService {
  constructor() {
    this.instance = inject(QueryClientService);
  }
  use(mutationFn, options = {}) {
    const sourceSubscription = new Subscription();
    const mutationObserver = new MutationObserver(this.instance, {
      ...options,
      mutationFn(vars) {
        return new Promise((res, rej) => {
          const subscription = mutationFn(vars).subscribe({
            next: res,
            error: rej
          });
          sourceSubscription.add(subscription);
        });
      }
    });
    mutationObserver['result$'] = new Observable(observer => {
      observer.next(mutationObserver.getCurrentResult());
      const mutationObserverDispose = mutationObserver.subscribe(notifyManager.batchCalls(result => {
        observer.next(result);
      }));
      return () => {
        sourceSubscription.unsubscribe();
        mutationObserverDispose();
      };
    });
    return mutationObserver;
  }
  static {
    this.ɵfac = function MutationService_Factory(t) {
      return new (t || MutationService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MutationService,
      factory: MutationService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MutationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const UseMutation = new InjectionToken('MutationProvider', {
  providedIn: 'root',
  factory() {
    const mutation = new MutationService();
    return mutation.use.bind(mutation);
  }
});
class InfiniteQueryService {
  constructor() {
    this.instance = inject(QueryClientService);
  }
  use(arg1, arg2, arg3) {
    const parsedOptions = parseQueryArgs(arg1, arg2, arg3);
    return buildQuery(this.instance, InfiniteQueryObserver, parsedOptions);
  }
  static {
    this.ɵfac = function InfiniteQueryService_Factory(t) {
      return new (t || InfiniteQueryService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: InfiniteQueryService,
      factory: InfiniteQueryService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InfiniteQueryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const UseInfiniteQuery = new InjectionToken('UseInfiniteQuery', {
  providedIn: 'root',
  factory() {
    const query = new InfiniteQueryService();
    return query.use.bind(query);
  }
});
class MutationResult {
  constructor(result = {}) {
    this.data = null;
    this.isError = false;
    this.isLoading = false;
    this.isSuccess = false;
    this.error = null;
    Object.assign(this, result);
  }
}
function useMutationResult(options = {}) {
  const store = new BehaviorSubject(new MutationResult(options));
  function update(data) {
    let resolved = data;
    if (typeof data === 'function') {
      resolved = data(store.getValue().data);
    }
    store.next(new MutationResult({
      data: resolved,
      isSuccess: true
    }));
  }
  return {
    result$: store.asObservable(),
    track() {
      return pipe(tap({
        next(data) {
          update(data);
        },
        subscribe() {
          store.next(new MutationResult({
            isLoading: true
          }));
        },
        error(err) {
          store.next(new MutationResult({
            error: err,
            isError: true
          }));
        }
      }));
    }
  };
}
function mapResultData(mapFn) {
  return map(result => {
    return {
      ...result,
      data: result.isSuccess ? mapFn(result.data) : result.data
    };
  });
}
function mapResultsData(mapFn) {
  return map(result => {
    const mappedResult = {
      isLoading: result.some(r => r.isLoading),
      isSuccess: result.every(r => r.isSuccess),
      isFetching: result.some(r => r.isFetching),
      isError: result.some(r => r.isError),
      error: result.find(r => r.isError)?.error,
      data: undefined
    };
    if (mappedResult.isSuccess) {
      mappedResult.data = mapFn(result.map(r => r.data));
    }
    return mappedResult;
  });
}
function filterError() {
  return filter(result => result.status === 'error');
}
function filterSuccess() {
  return filter(result => result.isSuccess);
}
function selectResult(mapFn) {
  return pipe(map(mapFn), distinctUntilChanged());
}
function tapSuccess(cb) {
  return tap(result => {
    if (result.isSuccess) {
      cb(result.data);
    }
  });
}
function tapError(cb) {
  return tap(result => {
    if (result.isError) {
      cb(result.error);
    }
  });
}
function startWithQueryResult() {
  return startWith({
    error: null,
    isError: false,
    isLoading: true,
    isSuccess: false
  });
}
function isPendingState(res) {
  return res.isLoading || res.isFetching;
}
class IsFetchingService {
  constructor() {
    this.instance = inject(QueryClientService);
  }
  use(arg1, arg2) {
    const [filters] = parseFilterArgs(arg1, arg2);
    return new Observable(obs => {
      obs.next(this.instance.isFetching(filters));
      this.instance.getQueryCache().subscribe(notifyManager.batchCalls(() => {
        obs.next(this.instance.isFetching(filters));
      }));
    }).pipe(distinctUntilChanged());
  }
  static {
    this.ɵfac = function IsFetchingService_Factory(t) {
      return new (t || IsFetchingService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: IsFetchingService,
      factory: IsFetchingService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IsFetchingService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const UseIsFetching = new InjectionToken('UseIsFetching', {
  providedIn: 'root',
  factory() {
    const query = new IsFetchingService();
    return query.use.bind(query);
  }
});
class IsMutatingService {
  constructor() {
    this.instance = inject(QueryClientService);
  }
  use(arg1, arg2) {
    const [filters] = parseMutationFilterArgs(arg1, arg2);
    return new Observable(obs => {
      obs.next(this.instance.isMutating(filters));
      this.instance.getMutationCache().subscribe(notifyManager.batchCalls(() => {
        obs.next(this.instance.isMutating(filters));
      }));
    }).pipe(distinctUntilChanged());
  }
  static {
    this.ɵfac = function IsMutatingService_Factory(t) {
      return new (t || IsMutatingService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: IsMutatingService,
      factory: IsMutatingService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IsMutatingService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const UseIsMutating = new InjectionToken('UseIsMutating', {
  providedIn: 'root',
  factory() {
    const query = new IsMutatingService();
    return query.use.bind(query);
  }
});
function queryOptions(options) {
  return options;
}
class PersistedQueryService {
  constructor() {
    this.useQuery = inject(UseQuery);
    this.client = inject(QueryClientService);
  }
  use(queryObserverOptionsFn) {
    let observer;
    return (key, params) => {
      const mergedOptions = {
        ...queryObserverOptionsFn(key, params),
        keepPreviousData: true
      };
      if (!observer) {
        observer = this.useQuery(mergedOptions);
      } else {
        const originalQueryFn = mergedOptions.queryFn;
        mergedOptions.queryFn = fromQueryFn(originalQueryFn, this.client, mergedOptions.queryKey);
        const options = this.client.defaultQueryOptions(mergedOptions);
        observer.setOptions(options);
      }
      return observer;
    };
  }
  static {
    this.ɵfac = function PersistedQueryService_Factory(t) {
      return new (t || PersistedQueryService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PersistedQueryService,
      factory: PersistedQueryService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PersistedQueryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const UsePersistedQuery = new InjectionToken('UsePersistedQuery', {
  providedIn: 'root',
  factory() {
    const query = new PersistedQueryService();
    return query.use.bind(query);
  }
});

/**
 * Generated bundle index. Do not edit.
 */

export { InfiniteQueryService, IsFetchingService, IsMutatingService, MutationService, PersistedQueryService, QUERY_CLIENT_OPTIONS, QueryClientService, QueryService, UseInfiniteQuery, UseIsFetching, UseIsMutating, UseMutation, UsePersistedQuery, UseQuery, allRequestsStatusOf, createSyncObserverResult, filterError, filterSuccess, fromQueryFn, isPendingState, mapResultData, mapResultsData, provideQueryClient, provideQueryClientOptions, queryOptions, selectResult, someRequestsStatusOf, startWithQueryResult, tapError, tapSuccess, useMutationResult };
